import React, { useEffect, useState } from 'react'
import lodinggif from '../admin_assets/assets/gif/loadwalk.gif'
import { bnb_balance, get_user_key, get_user_trading, jw_balance, referral_details, support_jw_trade_bot, update_market_price, user_details_two, user_roi_data_trading } from '../common/Api'
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from 'react-toastify'
// import '../assets/css/style.css'
import thirdimage from '../admin_assets/assets/img/illustrations/premimum.gif';
import BarChart from './Chart/BarGraph';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';

export default function Trading() {

    const [info, setinfo] = useState({})

    const [changegraph, setChangeGraph] = useState('br')
    const [show, setShow] = useState(false);
    const [data, setData] = useState([])
    const [principalamount, setPrincipalAmount] = useState(null)
    const [totalprofit, setTotalProfit] = useState(0)
    const [percetage, setPercentage] = useState(0)
    const [tradingdetail, setTradingDetail] = useState({})
    const [portfoliyo, setPortfoliyo] = useState(0)
    const [todayprofit, setTodayprofit] = useState(0)
    const [jwbalance, setJwBalance] = useState(0)
    const [bnbbalance, setBnbBalance] = useState(0)
    const [liveprice, setLivePrice] = useState(0)
    const [key, setKey] = useState('')

    const ph = JSON.parse(localStorage.getItem('ph'));

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [PageDetail, setPageDetail] = useState('wallet_page')
    const [address, setAddress] = useState('')

    const get_user_detail_two = async () => {
        const userdetail = await user_details_two(PageDetail)
        setAddress(userdetail.wallet_address)
    }

    const getUserTradingRoiData = async () => {
        try {
            const data = await user_roi_data_trading()
            //console.log('data', data)
            setPrincipalAmount(data?.principle_amount)
            setData(data?.data)
            const rewards = data?.data.map(item => parseFloat(item.reward));
            const total = rewards.reduce((acc, curr) => acc + curr, 0);
            const modifiedRewards = rewards.slice(0, -1);
            // console.log('modifiedRewards',modifiedRewards)
            const modifytotal = modifiedRewards.reduce((acc, curr) => acc + curr, 0);
            setTodayprofit(total - modifytotal);


            setTotalProfit(total.toFixed(2))
            setPortfoliyo(parseFloat(data?.principle_amount) + parseFloat(total.toFixed(2)))
            const percetage = total / data?.principle_amount * 100;
            //console.log('percetage',percetage)
            setPercentage(percetage)

        } catch (error) {
            console.log(error)
        }
    }

    const getBalance = async () => {
        try {
            const data = await jw_balance(address)
            console.log('balance', data)
            setJwBalance(data)
        } catch (error) {
            console.log(error)
        }
    }

    const getBnbBalance = async () => {
        try {
            const data = await bnb_balance(address)
            setBnbBalance(data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getBalance();
        getBnbBalance();
    }, [address])




    const getuserTradingdetail = async () => {
        try {
            const data = await get_user_trading();
            console.log('bot_plan', data)
            setTradingDetail(data)
        } catch (error) {
            console.log(error)
        }
    }

    const getUserKey = async () => {
        const key = await get_user_key(ph)
        setKey(key)
    }


    const getUpdatePrice = async () => {
        const data = await update_market_price();
        setLivePrice(data)

    }
    useEffect(() => {
        getuserTradingdetail();
        getUserTradingRoiData();
        get_user_detail_two();
        getUpdatePrice()
        getUserKey()

    }, [])

    const handleChart = async (e) => {

        try {
            //alert(e.tagret.value)
            setChangeGraph(e.tagret.value)
        } catch (error) {
            console.log(error)
        }
    }

    const handleBuyBot = async () => {
        try {
            setShow(true)

        } catch (error) {

        }
    }

    console.log(liveprice)

    const handleBotPlan = async () => {
        if (jwbalance < tradingdetail.Bot_fee / liveprice.Jw_MKT_Price_for_Withdraw) {
            return toast.error('Insufficent JW Balance')
        }

        if (bnbbalance < tradingdetail.MinBnbLimit) {
            return toast.error('Insufficent JW Balance')
        }

        const confirm = window.confirm('Are you sure to buy bot ? ')
        if (confirm) {
            const data = {
                Usdt_Amount: tradingdetail.Bot_fee,
                Amount: tradingdetail.Bot_fee / liveprice.Jw_MKT_Price_for_Withdraw,
                from_address: address,
                to_address: tradingdetail.JW_support,
                package_type: 4,
                api: key,
            }
            console.log(data)
            const datas = await support_jw_trade_bot(data)
            toast(datas.message)
            setInterval(() => {
                window.location.reload();
            }, 2000)

        }
    }







    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"

                    />

                    <div className="col-xl-8 mb-4 col-lg-7 col-12">

                        <div className="card tradecard">
                            <div className='card-body'>
                                <div className="row">

                                    <div className="col-lg-12 pt-5 pt-lg-4">
                                        {/* <div className="row">
                                            <div class="col-6 select2-primary">
                                                <select id="formtabs-language" class="form-select" tabindex="-1" aria-hidden="true" onChange={(e) => handleChart(e)}>
                                                    <option value="br">Bar Chart</option>
                                                    <option value="ln">Line Chart</option>
                                                    <option value="ca">Candle Stick Chrt</option>
                                                </select>
                                            </div>
                                            <div class="col-6 select2-primary">
                                                <select id="time" class="form-select" tabindex="-1" aria-hidden="true" onChange={handleGraphData}>
                                                    <option value="7">1 Week</option>
                                                    <option value="30">1 Month</option>
                                                    <option value="90">3 Month</option>
                                                    <option value="365">1 Year</option>
                                                </select>
                                            </div>
                                        </div> */}
                                        <div className='pt-4 chartArea'>
                                            <BarChart datas={data} amount={principalamount} profit={totalprofit} foliyo={portfoliyo} perce={percetage} todayprofita={todayprofit} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 mb-4 col-lg-5 col-12 d-none d-lg-block d-md-block">
                        <div className="card ">
                            <div className='card-body'>
                                <span className="text-center">
                                    <div className='text-center w-100'>
                                        <img src={thirdimage} alt="" className="img-fluid gem_img text-center" />
                                    </div>
                                    <h4 className="text-bold text-white">Start Trade</h4>
                                    <div className="pb-2">
                                        <Link to="/user/buy/plan" className="btn btn-success me-2">Claim</Link>
                                        {
                                            tradingdetail.Bot_status === 0 ? <>
                                                <a href="/user/trade" className="btn btn-warning p-1">Buy</a>
                                            </> : <>
                                                <button className='btn btn-warning' onClick={handleBuyBot}>Buy</button>
                                            </>
                                        }
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 mb-4 col-lg-5 col-12 d-block d-lg-none d-md-none">
                        <div className="card card-res bg-lin">
                            <div className='card-body'>
                                <span className="text-center">
                                    <div className='text-center w-100'>
                                        <img src={thirdimage} alt="" className="img-fluid gem_img text-center" />
                                    </div>
                                    <h4 className="text-bold text-white">Start Trade</h4>
                                    <div className="pb-2">
                                        {
                                            tradingdetail.Bot_status === 0 ? <>
                                                <a href="/user/buy/plan" className="p-1">Click here</a>
                                            </> : <>
                                                <button>Click Here</button>
                                            </>
                                        }

                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>




                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Buy Trading AI Bot

                        <h6>You Need To Pay For Purchase AI</h6>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table>
                        <tbody>
                            <tr>
                                <td>Available Balance</td>
                                <td> {jwbalance.toFixed(2)} JW</td>
                            </tr>
                            <tr>
                                <td>Trade Amount</td>
                                <td>$ {tradingdetail.Bot_fee && tradingdetail.Bot_fee}</td>
                            </tr>
                            <tr>
                                <td>Amount JW</td>
                                <td>{tradingdetail.Bot_fee / liveprice.Jw_MKT_Price_for_Withdraw} JW</td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={handleBotPlan}>
                        Pay with JW<br>
                        </br>
                        (JW will be burned)
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}
