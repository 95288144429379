import React, { useEffect, useState } from 'react'
import lodinggif from '../admin_assets/assets/gif/loadwalk.gif'
import { referral_details } from '../common/Api'
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from 'react-toastify'
import walletnew from '../admin_assets/assets/img/illustrations/wallet_new.webp'

export default function Wallet() {
    const [loading, setLoading] = useState(true);
    const [info, setinfo] = useState({})
    const [referal, setReferal] = useState('1VG3FG')
    const get_referral_details = async () => {
        try {
            const data = await referral_details()
            setReferal(data.referral_code)
            setinfo(data)
        } catch (error) {
            toast.error(`Api not load!`)
        }

        setLoading(false)
    }
    useEffect(() => {
        get_referral_details();
    }, [])

    const handleCopyCodes = () => {
        copy(`https://keepwalkking.io/referalcode/${info.referral_code}`);
        toast(`You have copied https://keepwalkking.io/referalcode/${info.referral_code}`)
    }
    return (
        <>
            {
                loading ? <div className='container-xxl flex-grow-1 container-p-y'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='loading'>
                                <img src={`${lodinggif}`} />
                            </div>

                        </div>
                    </div>
                </div> : <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row">
                        <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"

                        />

                        <div class="col-lg-6 mb-4">
                            <div class="card px-4 pt-3 h-100">
                                <div class=" d-flex flex-column gap-2">
                                    <h4 class="text-center text-white">WALLET</h4>
                                    <div class="d-flex justify-content-between align-items-center mb-3 pt-2 pt-lg-5 pt-md-5">
                                        <div class="text-center mb-3">
                                            <small class="para-color text-nowrap pb-2">Daily Trade Profit</small><br />
                                            <p className='text-white'>66.37 USDT</p>
                                        </div>
                                        <div class="text-center ">
                                            <img src={walletnew} alt="" class="img-fluid wallet_new_img" style={{ maxWidth: "90px" }} />
                                        </div>
                                        <div class="text-center">
                                            <small class="para-color text-nowrap pb-2">Bot Referral</small><br />
                                            <p className='text-white'>70.00 USDT</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 mb-4">
                            <div class="card px-4 pt-3 h-100">
                                <ul class="nav nav-tabs bg-transparent d-flex justify-content-center border-0">
                                    <li class="nav-item">
                                        <a class="nav-link active" href="#all" data-bs-toggle="tab">Daily Trade Profit</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#user_referal" data-bs-toggle="tab">Bot Referral</a>
                                    </li>
                                </ul>

                                <div className="container">
                                    <div class="tab-content bg-transparent">
                                        <div id="all" class="active tab-pane fade in show">
                                            <div class="table-responsive">
                                                <table class="table table-borderless border-top">
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center">
                                                                <small class="para-color text-nowrap">Oct 19, 2024</small>
                                                            </td>
                                                            <td className="text-center">
                                                                <p class="mb-0 fw-medium text-white">0.700000000 USDT</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">
                                                                <small class="para-color text-nowrap">Oct 19, 2024</small>
                                                            </td>
                                                            <td className="text-center">
                                                                <p class="mb-0 fw-medium text-white">0.700000000 USDT</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">
                                                                <small class="para-color text-nowrap">Oct 19, 2024</small>
                                                            </td>
                                                            <td className="text-center">
                                                                <p class="mb-0 fw-medium text-white">0.700000000 USDT</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">
                                                                <small class="para-color text-nowrap">Oct 19, 2024</small>
                                                            </td>
                                                            <td className="text-center">
                                                                <p class="mb-0 fw-medium text-white">0.700000000 USDT</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">
                                                                <small class="para-color text-nowrap">Oct 19, 2024</small>
                                                            </td>
                                                            <td className="text-center">
                                                                <p class="mb-0 fw-medium text-white">0.700000000 USDT</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div class="text-center">
                                                    <button type="button" class="btn btn-primary waves-effect waves-light mb-4">See All Transaction</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="user_referal" class="tab-pane fade">
                                            <div class="table-responsive">
                                                <table class="table table-borderless border-top">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <small class="para-color">Reward Recieved From</small>
                                                            </td>
                                                            <td>
                                                                <small class="text-white">Amount (USDT)</small>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <h6 className='mb-0 text-white'>TRADE</h6>
                                                                <p className='mb-0 text-white'>testinggmailyop</p>
                                                                <small class="para-color">Oct 08, 2024 16:39:38</small>
                                                            </td>
                                                            <td>
                                                                <p class="text-white pt-2">5.0000000000</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p className='mb-0 text-white'>testinggmailyop</p>
                                                                <small class="para-color">Oct 08, 2024 16:39:38</small>
                                                            </td>
                                                            <td>
                                                                <p class="text-white pt-2">5.0000000000</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p className='mb-0 text-white'>testinggmailyop</p>
                                                                <small class="para-color">Oct 08, 2024 16:39:38</small>
                                                            </td>
                                                            <td>
                                                                <p class="text-white pt-2">5.0000000000</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p className='mb-0 text-white'>testinggmailyop</p>
                                                                <small class="para-color">Oct 08, 2024 16:39:38</small>
                                                            </td>
                                                            <td>
                                                                <p class="text-white pt-2">5.0000000000</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div class="text-center">
                                                    <button type="button" class="btn btn-primary waves-effect waves-light mb-4">See All Transaction</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            }
        </>

    )
}



