import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import firstimage from '../../admin_assets/assets/img/illustrations/wallet_new.webp'
import { toast, ToastContainer } from 'react-toastify';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { bnb_balance, buynewStaking, jw_balance, jw_transfer, newStakingDeposit, stakeDetail, stakeExpire, staking_jw_transfer, stekeProcessReward, update_market_price } from '../../common/Api';
import StakingDepositHistory from './StakingDepositHistory';
import StakeRewardsHistory from './StakeRewardsHistory';

export default function StakingJW() {
    const navigate = useNavigate();
    const [amount, setAmount] = useState("")
    const [stakeamount, setStakeAmount] = useState("")
    const [fee, setFee] = useState("")
    const ph = JSON.parse(localStorage.getItem('ph'))
    const [marketPrice, setMarketPrice] = useState({})
    const [stakedetail, setStakeDetail] = useState({})
    const [disable, setDisable] = useState(false);

    const [jw, setJw] = useState(0)

    const getUpdatePrice = async () => {
        try {
            const data = await update_market_price()

            setMarketPrice(data)
        } catch (error) {
            console.log(error)
        }
    }

    const getStakeDetails = async () => {
        try {

            const stakeDetails = await stakeDetail()
            console.log(stakeDetails)
            setStakeDetail(stakeDetails)
        } catch (error) {
            console.log(error)
        }
    }

    const getStakeRewardHistory = async () => {
        try {
            const data = {
                "user_id": stakedetail.id
            }
            await stekeProcessReward(data)
        } catch (error) {
            console.log(error)
        }
    }
    const getStakeExpire = async () => {
        try {
            const data = {
                "id": stakedetail.id
            }
            await stakeExpire(data)
        } catch (error) {
            console.log(error)
        }
    }



    useEffect(() => {
        getUpdatePrice();
        getStakeDetails();
        getStakeRewardHistory();
        getStakeExpire();
        if (ph == 1) {
            navigate('/user/import/phrases')
        }
    }, [stakedetail?.id])

    const handleAmount = (e) => {
        setAmount(e.target.value)
        const jw = e.target.value / marketPrice.Jw_MKT_Price_for_Withdraw;
        setJw(jw)

    }

    const handleStakeAmount = (e) => {
        setStakeAmount(e.target.value)
        if (e.target.value >= 1 && e.target.value <= 200) {
            setFee(stakedetail.stakefeelesser200)
        } else if (e.target.value > 200 && e.target.value <= 500) {
            setFee(stakedetail.stakefeegreater200)
        } else if (e.target.value > 500 && e.target.value <= 1000) {
            setFee(stakedetail.stakefeegreater500)
        } else if (e.target.value > 1000 && e.target.value <= 2500) {
            setFee(stakedetail.stakefeegreater1000)
        } else if (e.target.value > 2500) {
            setFee(stakedetail.stakefeegreater2500)
        }

    }


    const handleDeposit = async () => {
        setDisable(true)
        if (ph == 1) {
            toast.error('Please Import Your Pharses')
            setDisable(false)
        } else {
            if (amount >= 0) {
                setAmount('')
                setJw('')
                const jwbalance = await jw_balance(stakedetail.wallet_address)
                const bnbBalance = await bnb_balance(stakedetail.wallet_address)

                if (jw > jwbalance) {
                    toast.error(`Insufficient JW balance in your wallet`)
                    setDisable(false)
                } else {
                    if (bnbBalance > 0.001) {
                        const data = await staking_jw_transfer(ph, jw, stakedetail.StakeJWsupport)
                        console.log(data)
                        if (data.status == true) {
                            const datas = await newStakingDeposit(amount, jw, data.result)
                            console.log(datas)
                            toast(datas.msg)
                            window.location.reload()
                        } else {
                            toast.error('Something went wrong!')
                            setDisable(false)
                        }
                    } else {
                        toast.error(`Insufficent BNB`)
                        setDisable(false)
                    }
                }

            } else {
                toast.error(`The minimum deposit amount must be greater than zero.`)
                setDisable(false)
            }
        }
        // setTimeout(() => {
        //     document.location.reload();
        //   }, 3000);
    }


    const handleStakedepiste = async () => {
        try {
            console.log(stakedetail?.newstakewallet)
            if (stakeamount == '') {
                return toast.error('Please Enter the stake amount!')
            }
            if (stakeamount < stakedetail?.minstake) {
                return toast.error(`The minimum staking amount is ${stakedetail?.minstake} USDT.`)
            }
             
            if (Number(stakeamount) > Number(stakedetail?.newstakewallet)) {
                return toast.error(`insufficent Balance`)
            }

            const data = await buynewStaking(stakedetail?.id, fee, stakeamount)
            toast(data.Msg)
            setStakeAmount('')

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <ToastContainer />

                <div className="col-lg-12 mb-4">



                    <div className="card px-4 pt-3 h-100">
                        <div className=" d-flex flex-column gap-2">
                            <div className="text-center py-3">
                                <img src={firstimage} alt="" className="img-fluid wallet_new_img" />
                                <p className='pt-4 text-white'>Current Staking :  {stakedetail?.stakeamount || 0} USDT</p>
                                <Link to="/user/staking/histroy">View Hsitory</Link>
                            </div>
                        </div>
                        <Tabs
                            defaultActiveKey="home"
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="home" title="Deposit">
                                <div className=" mt-3 ">
                                    <label for="depositamt" className="form-label">Enter Deposit Amount ( USDT )</label>
                                    <input type="number" className="form-control" id="depositamt" placeholder="Enter Deposit Amount" aria-describedby="" value={amount} onChange={(e) => handleAmount(e)} />
                                    {
                                        jw > 0 && (
                                            <p style={{ color: "green" }}>JW : {jw}</p>
                                        )
                                    }

                                    <button type="button" className="btn btn-primary waves-effect waves-light my-3" disabled={disable} onClick={handleDeposit}>Deposit with JW</button>

                                </div>

                            </Tab>
                            <Tab eventKey="profile" title="Staking">
                                <p className="text-white">Stake Wallet : {stakedetail?.newstakewallet || 0} USDT</p>
                                <div className=" mt-3 ">
                                    <label for="depositamt" className="form-label text-white">Enter Stake Amount</label>
                                    <input type="number" className="form-control" id="depositamt" placeholder="Enter Deposit Amount" aria-describedby="" value={stakeamount} onChange={(e) => handleStakeAmount(e)} />
                                    <div className='d-flex justify-content-between align-items-center'>
                                    <p className='text-white'>Min : {stakedetail?.minstake} USDT</p>
                                    <p className='text-white'>Stake Fees : {fee ? fee : 0} USDT</p>
                                    </div>
                                    <button type="button" className="btn btn-primary waves-effect waves-light my-3" onClick={handleStakedepiste}>Stake Now</button>

                                </div>
                            </Tab>

                        </Tabs>


                    </div>
                </div>

                <div className="col-xl-12 mb-4 col-lg-12 col-12">
                    <div className="card h-100 pt-4 overflow-hidden">
                        <Tabs
                            defaultActiveKey="home"
                            id="fill-tab-example"
                            className="mb-3 px-5"
                            fill
                        >
                            <Tab eventKey="home" title="Deposit History">
                                <StakingDepositHistory />
                            </Tab>
                            <Tab eventKey="profile" title="Rewards">
                                <StakeRewardsHistory />
                            </Tab>

                        </Tabs>

                    </div>
                </div>


            </div>

        </div>
    )
}
