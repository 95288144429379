import React, { useEffect, useState } from 'react'
import lodinggif from '../admin_assets/assets/gif/loadwalk.gif'
import { get_user_key, get_user_trading, getTradeTransferHistory, jw_balance, referral_details, tradeJWBotPusrchase, tradeUSDTBot, update_market_price, user_balance } from '../common/Api'
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from 'react-toastify'
import Pagination from "react-js-pagination";
import moment from 'moment';

export default function TradeWallet() {
    const ph = JSON.parse(localStorage.getItem('ph'))
    const [page, setPage] = useState(1)
    const [details, setDetails] = useState({})
    const [amountusdt, setAmountusdt] = useState('')
    const [amountjw, setAmountjm] = useState('')
    const [liveprice, setLivePrice] = useState({})
    const [usdtLiveBalance, setUsdtLiveBalance] = useState(null)
    const [jwLiveBalance, setJwLiveBalance] = useState(null)
    const [data, setData] = useState([])
    const [count, setCount] = useState(null)
    const [activePage, setActivePage] = useState(1);
    const [loader, setLoader] = useState(false)



    const getBotTradingDetails = async () => {
        try {

            const data = await get_user_trading()
            const price = await update_market_price()
            //console.log('price' , price)
            setLivePrice(price)
            //console.log(data)
            setDetails(data)
        } catch (error) {
            console.log(error)
        }
    }

    const getBalance = async () => {
        try {
            const usdt = await user_balance(details?.wallet_address)
            const jw = await jw_balance(details?.wallet_address)
            console.log(jw)
            setUsdtLiveBalance(usdt)
            setJwLiveBalance(jw)
            console.log(usdt)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getBalance()
    }, [details?.wallet_address])


    const getTransferDataHistory = async () => {
        try {
            const submitdata = {
                pageno: page,
                value: 'deposit'
            }
            const data = await getTradeTransferHistory(submitdata)
            // console.log('history', data)
            setData(data.Data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getBotTradingDetails()
        getTransferDataHistory()
    }, [])

    const handlePageChange = async (pageNumber) => {
        setActivePage(pageNumber)
        console.log(`active page is ${pageNumber}`);

        const submitdata = {
            pageno: pageNumber,
            value: 'deposit'
        }
        const data = await getTradeTransferHistory(submitdata)
        // console.log(data)
        setData(data.Data)
        //setCount(data.count)
    }

    const maskHex = (hex) => {
        // Replace characters from the 3rd to the second-to-last character with asterisks
        return hex.slice(0, 8) + '***********' + hex.slice(-4);
    };

    const handleAmount = (e) => {
        setAmountusdt(e.target.value)
        setAmountjm(e.target.value / liveprice.Current_Jw_MKT_Price)
    }
    console.log(amountusdt)

    const handleUSDTpayData = async () => {
        try {
            setLoader(true)
            if (amountusdt < 0) {
                setLoader(false)
                return toast.error('Please Deposite Greater Than 0')

            }

            if (usdtLiveBalance < amountusdt) {
                setLoader(false)
                return toast.error('Insufficent Balance!')

            }
            const key = await get_user_key(ph)
            if (key) {
                const submitData = {
                    Amount: amountusdt,
                    from_address: details?.wallet_address,
                    to_address: details?.USDT_support,
                    package_type: '4',
                    api: key
                }

                const data = await tradeUSDTBot(submitData)
                console.log(data)
                toast(data.Msg)
                setAmountusdt('')
                setLoader(false)
                getTransferDataHistory()
            } else {
                setLoader(false)
                return toast.error('Please Import Phrases!')

            }
            setLoader(false)

        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    const handleJWpayData = async () => {
        try {
            setLoader(true)
            if (amountusdt < 0) {
                setLoader(false)
                return toast.error('Please Deposite Greater Than 0')

            }

            if (jwLiveBalance < amountusdt) {
                setLoader(false)
                return toast.error('Insufficent Balance!')

            }
            const key = await get_user_key(ph)
            if (key) {
                const submitData = {
                    Amount: amountjw,
                    Usdt_Amount: amountusdt,
                    from_address: details?.wallet_address,
                    to_address: details?.JW_support,
                    package_type: '4',
                    api: key
                }

                const data = await tradeJWBotPusrchase(submitData)
                console.log(data)
                toast(data.Msg)
                setAmountusdt('')
                setLoader(false)
                getTransferDataHistory()
            } else {
                setLoader(false)
                return toast.error('Please Import Phrases!')

            }
            setLoader(false)

        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    const twodigitNumberShow = (amount) => {
        return Number(amount).toFixed(3)
    }

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"

                    />

                    <div className="col-xl-12 mb-4 col-lg-12 col-12">
                        <div class="card h-100">
                            <div className="card-body">
                                <div class=" d-flex flex-column gap-2">
                                    <div class="text-center py-3">
                                        <img src="/static/media/wallet_new.7641f712f747c75097f4.webp" alt="" class="img-fluid wallet_new_img" />
                                    </div>
                                    <div class="d-flex flex-column justify-content-center align-items-center ">
                                        <p class="mb-0 para-color">Trade Wallet Balance</p>
                                        <p class=" para-color pt-1">$ {details?.boatwallet}</p>
                                    </div>
                                </div>
                                <div class=" mt-3 ">
                                    <div className="d-flex justify-content-between">
                                        <p>USDT Live Balance : {usdtLiveBalance && twodigitNumberShow(usdtLiveBalance)} USDT</p>
                                        <p>JW Live Balance : {jwLiveBalance && twodigitNumberShow(jwLiveBalance)} JW</p>
                                    </div>

                                    <label for="depositamt" class="form-label text-white fs-5">Enter Deposit Amount ( USDT )</label>
                                    <input type="number" class="form-control" placeholder="Enter Deposit Amount" value={amountusdt} onChange={(e) => handleAmount(e)} />

                                </div>
                                <div class="mt-4">
                                    {
                                        details?.boatwallet >= details?.minJwLimit || details?.plan_amount >= details?.minJwLimit || details?.plan_amount
                                            + details?.boatwallet >= details.minJwLimit ? <>
                                            <button type="button" class="btn btn-primary waves-effect waves-light mt-3 mt-lg-0 mt-md-0" onClick={loader ? '' : handleUSDTpayData}>{loader ? 'Loading...' : 'Deposit with USDT'}</button>
                                        </> : <>
                                            {amountjw && <p>Deposit JW : {amountjw}</p>}
                                            <button type="button" class="btn btn-primary waves-effect waves-light mr-3 " onClick={loader ? '' : handleJWpayData}>{loader ? 'Loading...' : 'Deposit with JW'}</button>
                                        </>


                                    }

                                    {/* <button type="button" class="btn btn-primary waves-effect waves-light mr-3 " onClick={loader ? '' : handleJWpayData}>{loader ? 'Loading...' : 'Deposit with JW'}</button> */}


                                </div>
                                <div className="py-4">

                                    <div class="card">
                                        <h5 class="card-header text-white">Deposit History</h5>
                                        <div class="table-responsive text-nowrap">
                                            <table class="table">
                                                <thead className='text-center'>
                                                    <tr class="text-nowrap">
                                                        <th className='text-white'>S.No.</th>
                                                        <th className='text-white'>Amount</th>
                                                        <th className='text-white'>Hash</th>
                                                        <th className='text-white'>Explore</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="table-border-bottom-0">
                                                    {
                                                        data.map((items, i) => {
                                                            return (
                                                                <tr className='text-center'>
                                                                    <td className='para-color'>{i + 1}</td>
                                                                    <td className='text-center'>
                                                                        <div className='para-color'>
                                                                            <span>{items?.amount_usdt} USDT</span>
                                                                            <p className="mb-0">{moment(items?.created_on).format('MMM D, YYYY hh:mm A')}</p>
                                                                        </div>
                                                                    </td>
                                                                    <td className='para-color'>{maskHex(items?.Hash)}</td>
                                                                    <td className='para-color'><a href={`https://bscscan.com/tx/${items?.Hash}`} target='_blank'><i class="fa-solid fa-arrow-up-right-from-square"></i></a></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }


                                                </tbody>
                                            </table>
                                            {
                                                data.length > 10 && <Pagination
                                                    activePage={activePage}
                                                    itemsCountPerPage={10}
                                                    totalItemsCount={count}
                                                    pageRangeDisplayed={count / 10}
                                                    onChange={handlePageChange}
                                                />
                                            }

                                            {/* <div className='text-center'>
                                                <button type="button" class="btn btn-primary waves-effect waves-light mb-4">See All Transaction</button>
                                            </div> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>

    )
}
