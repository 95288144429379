import React, { useEffect, useState } from 'react'
import lodinggif from '../admin_assets/assets/gif/loadwalk.gif'
import { addLegBusinessApi, buyBoatPlan, get_user_trading, referral_details } from '../common/Api'
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from 'react-toastify'
// import '../assets/css/style.css'
import thirdimage from '../admin_assets/assets/img/illustrations/premimum.gif';
import { Link } from 'react-router-dom';

export default function Trade() {

    const [tradingdetail, setTradingDetail] = useState({})
    const [amount, setAmount] = useState('')

    const getuserTradingdetail = async () => {
        try {
            const data = await get_user_trading();
            console.log('bot_plan', data)
            setTradingDetail(data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getuserTradingdetail();
    }, [])

    const handleBotTradingAmout = async () => {
        try {
            if (amount == '') {
                return toast.error('Please Enter Amount')
            }

            if (amount < Number(tradingdetail.plan_minimum)) {
                return toast.error(`Minimum deposit amount is $${tradingdetail.plan_minimum}`)
            }
            if (amount > Number(tradingdetail.boatwallet)) {
                return toast.error(`Insufficient Balance to deposit in trade.`)
            }
            const data = {
                ID: tradingdetail.id,
                wallet_type: "1",
                purchase_amount: amount,

            }
            const datas = await buyBoatPlan(data)
            console.log(datas)
            if (datas.status == 'true') {
                toast.success(datas.Msg)
                const submitData = {
                    Plan: amount,
                    id: tradingdetail.id,
                    reff_id: tradingdetail.reff_id
                }
                await addLegBusinessApi(submitData)
                setAmount('')
                getuserTradingdetail();
            }

        } catch (error) {
            console.log(error)
        }
    }


    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"

                    />

                    <div className="col-xl-4 mb-4 col-lg-4 col-md-6 col-12 ">
                        <div className="card tradecard h-100">
                            <div className='card-body'>
                                <span className="text-center">
                                    <div className='text-center w-100'>
                                        <img src={thirdimage} alt="" className="img-fluid gem_img text-center" />
                                    </div>
                                    <h4 className="text-bold text-white">Trade</h4>
                                    <div className="pb-3">
                                        <Link to='/user/trade-history'>View trade history</Link>
                                    </div>
                                    <div className="">
                                        <Link to='/user/bot-history'>View bot history</Link>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-8 mb-4 col-lg-6 col-12">
                        <div className="card  h-100">
                            <div className='card-body'>
                                <div>
                                    <h4 className='text-white'>Enter Trade Amount</h4>
                                    <input type="number" class="form-control mt-2" placeholder="0.00" max="0" value={amount} onChange={(e) => setAmount(e.target.value)} />
                                    <div className="d-flex justify-content-between">
                                        <p className='mb-0 mt-2 text-white para-color' style={{ fontSize: '14px' }}>Starting trade : $ {tradingdetail.plan_minimum}</p>
                                        <p className='mb-4 mt-2 text-white para-color' style={{ fontSize: '14px' }}>Wallet Balance : $ {tradingdetail.boatwallet}</p>
                                    </div>

                                    <button type="submit" class="btn btn-primary waves-effect waves-light" onClick={handleBotTradingAmout}>Starting trading</button>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>

        </>

    )
}
