import React, { useEffect, useState } from 'react'
import { bnb_balance, get_user_trading, get_withdraw_fees, resend_otp, shift_all_plan, stakeWithdrawal, update_market_price, update_plan_end_date, user_details_two, withdraw_request, withdrawUSDT_request } from '../common/Api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

export default function TradeWithdrawal() {
    const ph = JSON.parse(localStorage.getItem('ph'));
    const navigation = useNavigate();

    const [email, setEmail] = useState(false)
    const [tfa, setTfa] = useState(false)
    const [info, setInfo] = useState({})
    const [plan, setPlan] = useState({})
    const [pageDetail, setPageDetail] = useState('Withdraw_page')
    const [userEmail, setUserEmail] = useState('')
    const [balance, setBalance] = useState(0)
    const [userReward, setUserRewards] = useState(0)
    const [rollonreward, setRollonreward] = useState(0)
    const [amount, setAmount] = useState('')
    const [address, setAddress] = useState('')
    const [pin, setPin] = useState('')
    const [twoCode, setTwoFA] = useState('')
    const [emailOtp, setEmailOtp] = useState('')
    const [version, setVersion] = useState(0)
    const [fee, setFee] = useState(0)
    const [received, setReceived] = useState(0)
    const [message, setmessage] = useState(null)

    const [directCount, setDirectCount] = useState(3)
    const [show, setShow] = useState(true);

    //25-11-2024

    const [botdetail, setBotdetail] = useState({})
    const [marketPrice, setMarketPrice] = useState({})


    // 25-11-2024

    const getTradeBotDetail = async () => {
        try {
            const details = await get_user_trading()
            console.log('bot trade', details)
            setBotdetail(details)
        } catch (error) {
            console.log(error)
        }
    }


    const handleClose = () => setShow(false);

    const getUpdatePrice = async () => {
        try {
            const data = await update_market_price()
            console.log(data)
            setMarketPrice(data)
        } catch (error) {
            console.log(error)
        }
    }










    useEffect(() => {





        if (ph == 1) {
            navigation('/user/import/phrases')
        }
        getUpdatePrice()
        getTradeBotDetail()

    }, [ph])




    const handleSendOtp = async () => {
        const result = await resend_otp()

        toast(result.Msg)
    }

    // admin_stop_withdraw1 ==1 means withdrawal stop
    //admin_stop_withdraw1 ==0 means withdrawal Open

    const handleAmount = (e) => {
        console.log(e.target.value)
        setAmount(e.target.value)
        const wallet = document.querySelector("#selectwallet").value;
        let value;
        if (wallet == 1) {
            value = e.target.value * botdetail?.BotReffFee / 100;
        } else if (wallet == 2) {
            value = e.target.value * botdetail?.TradeReffFee / 100;
        } else {
            value = e.target.value * botdetail?.TradeWithFee / 100;
        }

        setReceived(e.target.value - value)


    }


    const handleWidthdrawal = async () => {


        try {
            if (amount == '') {
                return toast.error('Please enter the amount!')
            }

            if (botdetail?.WithdrawActive == false) {
                return toast.error(`You can withdraw your balance on ${botdetail?.Day} only`)
            }



            const wallet = document.querySelector("#selectwallet").value;

            if (wallet == 1) {
                if (amount > Number(botdetail?.BotReffMinWithdraw)) {
                    if (amount > Number(botdetail?.Boatreferalincome)) {
                        return toast.error(`Insufficent Balance in Bot Referal Income`)
                    }
                } else {
                    return toast.error(`The minimum withdrawal amount is ${botdetail?.BotReffMinWithdraw}.`)
                }


            } else if (wallet == 2) {
                if (amount > Number(botdetail?.TradeReffMinWithdraw)) {
                    if (amount > Number(botdetail?.tradereferalincome)) {
                        return toast.error(`Insufficent Balance in Trade Referal Income`)
                    }
                } else {
                    return toast.error(`The minimum withdrawal amount is ${botdetail?.TradeReffMinWithdraw}.`)
                }


            } else {

                if (amount > Number(botdetail?.TradeMinWithdraw)) {
                    if (amount > Number(botdetail?.tradewithdrawblance)) {
                        return toast.error(`Insufficent Balance in Trade wallet Income`)
                    }
                } else {
                    return toast.error(`The minimum withdrawal amount is ${botdetail?.TradeMinWithdraw}.`)
                }
            }



            if (emailOtp == '') {
                return toast.error(`Please Enter OTP`);
            }

            if (pin == '') {
                return toast.error(`Please Enter Pin`);
            }

            if (wallet != 1) {
                const bnbBalance = await bnb_balance(botdetail?.wallet_address)
                if (bnbBalance < 0.001) {
                    return toast.error(`Insufficent BNB Balance`);
                }
            }



            const submitData = {
                user_withdraw_request: "0.0",
                premium_transfer_amt: "0.0",
                Address: botdetail.wallet_address,
                Amount: amount,
                Two_Fa: emailOtp,
                User_PK: ph,
                Wei_amount: botdetail?.PType == 'INR' ? '123456789.0' : wallet == 1 ? received / Number(marketPrice?.Jw_MKT_Price_for_Withdraw) : 0,
                pin: pin,
                security_type: "EMAIL",
                wallet_type: wallet == 3 ? '5' : '4',
                stake_credit_converted: "0.0",
                price: wallet == 1 ? amount - amount * botdetail?.BotReffFee / 100 : wallet == 2 ? amount - amount * botdetail?.TradeReffFee / 100 : amount - amount * botdetail?.TradeMinWithdraw / 100
            }
            if (wallet == 1) {
                const data = await withdraw_request(submitData)
                toast(data.Msg)

                setInterval(() => {
                    window.location.reload()
                }, 2000)

            } else {
                const data = await withdrawUSDT_request(submitData)
                toast(data.Msg)
                setInterval(() => {
                    window.location.reload()
                }, 2000)
            }

            // const data = await stakeWithdrawal(submitData)
            // // console.log(data)

            // toast(data.Msg)

            // window.location.reload();



        } catch (error) {
            console.log(error)
        }


    }

    const handleAllmessage = (message) => {
        toast(`${message}`)
    }






    return (
        <div class="container-xxl flex-grow-1 container-p-y">
            <div class="row">
                <ToastContainer />

                <div class="col-lg-6 mb-4" >
                    <div class="card px-5 py-4 h-100">
                        <div class="content_internal_transfer">
                            <h5 class="text-center mb-0">TRADE WITHDRAWAL</h5>
                            <div class="d-flex justify-content-between align-items-center pt-4">
                                <div class="text-center">
                                    <p class="mb-2">Bot Referal  Income</p>
                                    <h6>{botdetail?.Boatreferalincome || 0} USDT</h6>
                                </div>
                                <div class="text-center">
                                    <p class="mb-2">Trade Referal Income</p>
                                    <h6>{botdetail?.tradereferalincome || 0} USDT</h6>
                                </div>
                            </div>
                            <div class="text-center">
                                <p class="mb-2">Trade wallet</p>
                                <h6 class="mb-2">{botdetail?.tradewithdrawblance} USDT</h6>

                                <div class="d-flex justify-content-between align-items-center pt-4">
                                    <div class="text-center">
                                        <div class="day_count mb-2">
                                            <span>TWA</span>
                                            <i class="fa-solid fa-circle-exclamation text-white"
                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                data-bs-custom-class="custom-tooltip"
                                                data-bs-title="3 user remain to activate Roll On Rewards. (Level 1)" onClick={() => handleAllmessage(`Total amount available to withdrawal`)}></i>
                                        </div>
                                        <h6>{botdetail?.ActualTotalWithdrawAmount} USDT</h6>
                                    </div>
                                    <div class="text-center">
                                        <div class="day_count mb-2">
                                            <span>BWA</span>
                                            <i class="fa-solid fa-circle-exclamation text-white"
                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                data-bs-custom-class="custom-tooltip"
                                                data-bs-title="3 user remain to activate Roll On Rewards. (Level 1)" onClick={() => handleAllmessage(`Balance amount available to withdrawal`)}></i>
                                        </div>
                                        <h6>{botdetail?.TotalWithdrawAmount} USDT</h6>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mb-4">
                    <div class="card h-100 px-4 pt-3">
                        <div class="row justify-content-between align-items-center">
                            <div>

                                <p class="text-end"><a href="/user/withdrawal/history">View History</a></p>
                                {/* <div class="mb-3">
                                    <label for="selectpickerBasic" class="form-label">Withdrawal Type</label>
                                    <div class="dropdown bootstrap-select w-100 dropup">
                                        <div class="dropdown bootstrap-select w-100">
                                            <select id="wallettype" class="form-control selectpicker w-100" data-style="btn-default"
                                                tabindex="null">
                                                {
                                                    info.USDT_status === 1 ? <option value="1">USDT</option> : <option value="2">JW</option>
                                                }



                                            </select>

                                        </div>

                                    </div>


                                </div> */}
                                <div class="mb-3">
                                    <label for="selectpickerBasic" class="form-label">Select Wallet</label>
                                    <div class="dropdown bootstrap-select w-100 dropup">
                                        <div class="dropdown bootstrap-select w-100">
                                            <select id="selectwallet" class="form-control selectpicker w-100" data-style="btn-default"
                                                tabindex="null">
                                                <option value="0">Select Wallet</option>
                                                <option value="1">Bot Reward</option>
                                                <option value="2">Trade Reward</option>
                                                <option value="3">Trade Wallet</option>


                                            </select>

                                        </div>

                                    </div>


                                </div>

                                <div class="">
                                    <label for="amount" class="form-label">Amount(USDT)</label>
                                    <input type="number" class="form-control mb-2" id="fiat_value" placeholder="0.00"
                                        aria-describedby="" value={amount} onChange={(e) => handleAmount(e)} />
                                    {
                                        received > 0 &&

                                        <span style={{ color: "green" }}>You Have To Recieved Amount {received}</span>
                                    }
                                </div>




                            </div>
                        </div>

                        <div class="content-backdrop fade"></div>
                    </div>

                </div>
                <div class="col-12 mb-4">
                    <div class="card h-100 px-4 pt-3">
                        <div class="row justify-content-between align-items-center">
                            <div>

                                <div class="col-md-12 my-3">
                                    <label for="address" class="form-label">Address</label>
                                    <input type="text" class="form-control" id="address" placeholder=""
                                        value={botdetail?.wallet_address} onChange={(e) => setAddress(e.target.value)} aria-describedby="" readOnly={true} />

                                </div>
                                <div class="col-md-12 my-3">
                                    <label for="pin" class="form-label">Enter Pin</label>
                                    <input type="password" class="form-control" id="address" placeholder="Enter Your Pin" value={pin} onChange={(e) => setPin(e.target.value)}
                                        aria-describedby="" required={true} />

                                </div>
                                <div class="row mb-4">
                                    <div class="col-md">
                                        <div class="form-check custom-option custom-option-basic">
                                            <label class="form-check-label custom-option-content" for="customRadioTemp2">
                                                <input name="customRadioTemp" class="form-check-input" type="radio" value="email"
                                                    id="customRadioTemp2" checked={true} />
                                                <span class="custom-option-header">
                                                    <span class="h6 mb-0">Email</span>

                                                </span>

                                            </label>
                                        </div>
                                    </div>

                                    {/* <div class="col-md mb-md-0 mb-2">
                                        <div class="form-check custom-option custom-option-basic">
                                            <label class="form-check-label custom-option-content" for="customRadioTemp1">
                                                <input name="customRadioTemp" class="form-check-input" type="radio" value="tfa"
                                                    id="customRadioTemp1" onClick={handleChecked} />
                                                <span class="custom-option-header">
                                                    <span class="h6 mb-0">TFA</span>

                                                </span>

                                            </label>
                                        </div>
                                    </div>

                                    {
                                        tfa && <div class="col-12 mb-4 mt-3 position-relative">
                                            <label for="2fa" class="form-label">2FA</label>
                                            <input type="text" class="form-control" id="2fa" placeholder="Enter 2FA Code" value={twoCode}
                                                aria-describedby="" onChange={(e) => setTwoFA(e.target.value)} />


                                        </div>
                                    } */}

                                    {/* {
                                        email && <>
                                            <div class="col-12 mb-4 mt-3 position-relative">
                                                <label for="2fa" class="form-label">Email</label>
                                                <input type="text" class="form-control" id="email" value={userEmail} aria-describedby="" onChange={(e) => setUserEmail(e.target.value)} readOnly={true} />
                                                <p style={{ cursor: "pointer", color: 'red' }} onClick={handleSendOtp}>Send OTP</p>

                                            </div>
                                            <div class="col-12 mb-4 mt-3 position-relative">
                                                <label for="2fa" class="form-label">Email OTP</label>
                                                <input type="number" class="form-control" id="emailOtp" placeholder="OTP" aria-describedby="" value={emailOtp} onChange={(e) => setEmailOtp(e.target.value)} />


                                            </div>
                                        </>
                                    } */}


                                    <div class="col-12 mb-4 mt-3 position-relative">
                                        <label for="2fa" class="form-label">Email</label>
                                        <input type="text" class="form-control" id="email" value={botdetail?.Email} aria-describedby="" onChange={(e) => setUserEmail(e.target.value)} readOnly={true} />
                                        <p style={{ cursor: "pointer", color: '#ae9055' }} onClick={handleSendOtp} >Send OTP</p>

                                    </div>
                                    <div class="col-12 mb-4 mt-3 position-relative">
                                        <label for="2fa" class="form-label">Email OTP</label>
                                        <input type="number" class="form-control" id="emailOtp" placeholder="OTP" aria-describedby="" value={emailOtp} onChange={(e) => setEmailOtp(e.target.value)} />


                                    </div>


                                </div>





                                <button type="button" class="btn btn-primary waves-effect waves-light mb-4" onClick={handleWidthdrawal}>Withdraw</button>
                            </div>
                        </div>


                        <div class="mt-3">
                            <div class="content-backdrop fade"></div>
                        </div>

                    </div>

                </div>

            </div>


            <div class="layout-overlay layout-menu-toggle"></div>


            <div class="drag-target"></div>
            {/* <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4 style={{ textAlign: "center" }}>You should have BNB Balance to withdraw, Minimum balance of {plan?.minimum_BNB_Balance}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='tab-bor'>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Health Monthly</td>
                                    <td>Referral Monthly</td>
                                </tr>
                                <tr>
                                    <td>Withdraw limit</td>
                                    <td>Withdraw limit</td>
                                </tr>
                                <tr>
                                    <td>{plan?.health_withdraw_minimum_limit} - {plan?.health_withdraw_maximum_limit}</td>
                                    <td>{plan?.referral_withdraw_minimum_limit} - {plan?.referral_withdraw_maximum_limit}</td>
                                </tr>
                                <tr>
                                    <td>USDT Per Month</td>
                                    <td>USDT Per Month</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Understood
                    </Button>

                </Modal.Footer>
            </Modal> */}
        </div>
    )
}
