import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { update_market_price, get_withdraw_fees, resend_otp, shift_all_plan, update_plan_end_date, user_details_two, withdraw_request, withdrawUSDT_request, stakeDetail, stakeWithdrawal, bnb_balance } from '../../common/Api';

export default function StakeWithdrawal() {
    const ph = JSON.parse(localStorage.getItem('ph'));
    const navigation = useNavigate();

    const [email, setEmail] = useState(false)


    const [plan, setPlan] = useState({})
    const [pageDetail, setPageDetail] = useState('Withdraw_page')
    const [userEmail, setUserEmail] = useState('')
    const [balance, setBalance] = useState(0)
    const [userReward, setUserRewards] = useState(0)
    const [rollonreward, setRollonreward] = useState(0)
    const [amount, setAmount] = useState('')
    const [address, setAddress] = useState('')
    const [pin, setPin] = useState('')
    const [twoCode, setTwoFA] = useState('')
    const [emailOtp, setEmailOtp] = useState('')
    const [version, setVersion] = useState(0)
    const [fee, setFee] = useState(0)
    const [received, setReceived] = useState(0)
    const [message, setmessage] = useState(null)
    const [marketPrice, setMarketPrice] = useState({})
    const [directCount, setDirectCount] = useState(3)

    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);



    //new 20-11-2024
    const [stakedetail, setStakeDetail] = useState({})

    const getUpdatePrice = async () => {
        try {
            const data = await update_market_price()
            // console.log('update price',data)
            setMarketPrice(data)
        } catch (error) {
            console.log(error)
        }
    }








    useEffect(() => {
        if (ph == 1) {
            navigation('/user/import/phrases')
        }
        getUpdatePrice()

    }, [ph])




    const handleSendOtp = async () => {
        const result = await resend_otp()

        toast(result.Msg)
    }

    // admin_stop_withdraw1 ==1 means withdrawal stop
    //admin_stop_withdraw1 ==0 means withdrawal Open

    const handleWidthdrawal = async () => {


        try {
            if (amount == '') {
                return toast.error('Please enter the amount!')
            }

            if (stakedetail.is_withdraw_active == false) {
                return toast.error(` ${stakedetail.Day}  `)
            }

            const wallet = document.querySelector("#selectpickerBasic").value;

            if (wallet == 3) {
                // Wallet is 3: Validate against StakeWithdrawrefFee and StakeWithdrawrefMax
                if (amount < Number(stakedetail.StakeWithdrawrefMin) || amount > Number(stakedetail.StakeWithdrawrefMax)) {
                    return toast.error(`Amount must be between Min: ${stakedetail.StakeWithdrawrefMin} and Max: ${stakedetail.StakeWithdrawrefMax}`);
                }
            } else {
                // Wallet is not 3: Validate against StakeWithdrawMin and StakeWithdrawMax
                if (amount < Number(stakedetail.StakeWithdrawMin) || amount > Number(stakedetail.StakeWithdrawMax)) {
                    return toast.error(`Amount must be between Min: ${stakedetail.StakeWithdrawMin} and Max: ${stakedetail.StakeWithdrawMax}`);
                }
            }


            if (amount > Number(stakedetail?.BalanceWithdrawAmount)) {
                return toast.error(`You can withdrawal only  ${stakedetail?.BalanceWithdrawAmount} $`)
            }

            if (wallet == 3) {
                if (amount > Number(stakedetail?.newstakereff)) {
                    return toast.error(`Insufficent Balance in Stake Rewards`);
                }
            } else {
                if (amount > Number(stakedetail?.newstakewithdraw)) {
                    return toast.error(`Insufficent Balance in Stake Withdraw`);
                }
            }

            if(emailOtp == ''){
                return toast.error(`Please Enter OTP`);
            }

            if(pin == ''){
                return toast.error(`Please Enter Pin`);
            }

            const bnbBalance = await bnb_balance(stakedetail.wallet_address)
            if (bnbBalance < Number(stakedetail?.mimBNB)) {
                return toast.error(`Insufficent BNB Balance`);
            }


            const submitData = {
                Address: stakedetail.wallet_address,
                Amount: amount,
                Two_Fa: emailOtp,
                User_PK: ph,
                Wei_amount: received,
                pin: pin,
                security_type: "EMAIL",
                stake_withdraw_usdt: amount - (amount * stakedetail.StakeWithdrawFee / 100),
                wallet_type: wallet
            }

            const data = await stakeWithdrawal(submitData)
            // console.log(data)

            toast(data.Msg)

           // window.location.reload();



        } catch (error) {
            console.log(error)
        }


    }

    const handleAmount = (e) => {
        console.log(e.target.value)
        const value = e.target.value * stakedetail.StakeWithdrawFee / 100;
        setReceived((e.target.value - value) / marketPrice.Jw_MKT_Price_for_Withdraw)
        setAmount(e.target.value)

    }

    const handleAllmessage = (message) => {
        toast(`${message}`)
    }

    const getStakeDetails = async () => {
        try {

            const stakeDetails = await stakeDetail()
            console.log(stakeDetails)
            setStakeDetail(stakeDetails)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getStakeDetails()
    }, [])





    return (
        <div class="container-xxl flex-grow-1 container-p-y">
            <div class="row">
                <ToastContainer />

                <div class="col-lg-6 mb-4" >
                    <div class="card px-5 py-4 h-100">
                        <div class="content_internal_transfer">
                            <h5 class="text-center mb-0">STAKE WITHDRAWAL</h5>
                            <div class="d-flex justify-content-between align-items-center pt-4">
                                <div class="text-center">
                                    <p class="mb-2">Stake Reward</p>
                                    <h6>{stakedetail?.newstakereff || 0} USDT</h6>
                                </div>
                                <div class="text-center">
                                    <p class="mb-2">Stake Withdraw</p>
                                    <h6>{stakedetail?.newstakewithdraw || 0} USDT</h6>
                                </div>
                            </div>
                            <div class="text-center">

                                <div class="d-flex justify-content-between align-items-center pt-4">
                                    <div class="text-center">
                                        <div class="day_count mb-2">
                                            <span>TWA</span>
                                            <i class="fa-solid fa-circle-exclamation text-white"
                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                data-bs-custom-class="custom-tooltip"
                                                data-bs-title="3 user remain to activate Roll On Rewards. (Level 1)" onClick={() => handleAllmessage(`Total amount available to withdrawal`)}></i>
                                        </div>
                                        <h6>{stakedetail?.TotalWithdrawAmount || 0} USDT</h6>
                                    </div>
                                    <div class="text-center">
                                        <div class="day_count mb-2">
                                            <span>BWA</span>
                                            <i class="fa-solid fa-circle-exclamation text-white"
                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                data-bs-custom-class="custom-tooltip"
                                                data-bs-title="3 user remain to activate Roll On Rewards. (Level 1)" onClick={() => handleAllmessage(`Balance amount available to withdrawal`)}></i>
                                        </div>
                                        <h6>{stakedetail?.BalanceWithdrawAmount || 0} USDT</h6>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mb-4">
                    <div class="card h-100 px-4 pt-3">
                        <div class="row justify-content-between align-items-center">
                            <div>

                                <p class="text-end"><a href="/user/withdrawal/history">View History</a></p>

                                <div class="mb-3">
                                    <label for="selectpickerBasic" class="form-label">Select Wallet</label>
                                    <div class="dropdown bootstrap-select w-100 dropup">
                                        <div class="dropdown bootstrap-select w-100">
                                            <select id="selectpickerBasic" class="selectpicker w-100" data-style="btn-default"
                                                tabindex="null">
                                                <option value="3">Stake Reward</option>
                                                <option value="4">Stake Withdraw</option>


                                            </select>

                                        </div>

                                    </div>


                                </div>

                                <div class="">
                                    <label for="amount" class="form-label">Amount(USDT)</label>
                                    <input type="number" class="form-control mb-2" id="fiat_value" placeholder="0.00"
                                        aria-describedby="" value={amount} onChange={(e) => handleAmount(e)} />
                                    {
                                        received > 0 &&

                                        <span style={{ color: "green" }}>You Have To Recieved JW Amount {received}</span>
                                    }
                                </div>




                            </div>
                        </div>

                        <div class="content-backdrop fade"></div>
                    </div>

                </div>
                <div class="col-12 mb-4">
                    <div class="card h-100 px-4 pt-3">
                        <div class="row justify-content-between align-items-center">
                            <div>

                                <p class="mt-2">Community support : {stakedetail?.StakeWithdrawFee} %
                                </p>
                                <div class="col-md-12 my-3">
                                    <label for="address" class="form-label">Address</label>
                                    <input type="text" class="form-control" id="address" placeholder=""
                                        value={stakedetail?.wallet_address} aria-describedby="" readOnly={true} />

                                </div>
                                <div class="col-md-12 my-3">
                                    <label for="pin" class="form-label">Enter Pin</label>
                                    <input type="password" class="form-control" id="address" placeholder="Enter Your Pin" value={pin} onChange={(e) => setPin(e.target.value)}
                                        aria-describedby="" />

                                </div>
                                <div class="row mb-4">
                                    <div class="col-md">
                                        <div class="form-check custom-option custom-option-basic">
                                            <label class="form-check-label custom-option-content" for="customRadioTemp2">
                                                <input name="customRadioTemp" class="form-check-input" type="radio" value="email"
                                                    id="customRadioTemp2" checked={true} />
                                                <span class="custom-option-header">
                                                    <span class="h6 mb-0">Email</span>

                                                </span>

                                            </label>
                                        </div>
                                    </div>





                                    <div class="col-12 mb-4 mt-3 position-relative">
                                        <label for="2fa" class="form-label">Email</label>
                                        <input type="text" class="form-control" id="email" value={stakedetail?.Email} aria-describedby="" readOnly={true} />
                                        <p style={{ cursor: "pointer", color: '#e1b765' }} onClick={handleSendOtp}>Send OTP</p>

                                    </div>
                                    <div class="col-12 mb-4 mt-3 position-relative">
                                        <label for="2fa" class="form-label">Email OTP</label>
                                        <input type="number" class="form-control" id="emailOtp" placeholder="OTP" aria-describedby="" value={emailOtp} onChange={(e) => setEmailOtp(e.target.value)} />


                                    </div>


                                </div>





                                <button type="button" class="btn btn-primary waves-effect waves-light mb-4" onClick={handleWidthdrawal}>Withdraw</button>
                            </div>
                        </div>


                        <div class="mt-3">


                            {/* <div class="modal fade" id="modalScrollable" tabindex="-1" style={{ display: "none" }}
                                aria-hidden="true">
                                <div class="modal-dialog modal-dialog-scrollable" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">

                                        </div>
                                        <div class="modal-body">
                                            <p class="text-center fs-5 ">
                                                You Should have BNB Balance to Withdraw, Minimum balance of {plan.minimum_BNB_Balance}
                                            </p>
                                            <div class="row">
                                                <div class="col-6 golden_bg fs-6">
                                                    <div class="">
                                                        Heath Monthly Withdraw limit
                                                    </div>
                                                    <div class="">
                                                        600 - 750 USDT Per Month

                                                    </div>
                                                </div>
                                                <div class="col-6 golden_bg fs-6">
                                                    <div>
                                                        Referral Monthly Withdraw limit
                                                    </div>
                                                    <div class="">
                                                        1 - 800 USDT Per Month
                                                    </div>
                                                </div>
                                                <div class="col-12 text-center my-3">
                                                    <button type="button" class="btn btn-dark waves-effect waves-light"
                                                        data-bs-dismiss="modal">Understood</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div> */}


                            <div class="content-backdrop fade"></div>
                        </div>

                    </div>

                </div>

            </div>


            <div class="layout-overlay layout-menu-toggle"></div>


            <div class="drag-target"></div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4 style={{ textAlign: "center" }}>You should have BNB Balance to withdraw, Minimum balance of {stakedetail?.mimBNB}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='tab-bor'>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Stake Reward</td>
                                    <td>Stake Withdraw</td>
                                </tr>
                                <tr>
                                    <td>Withdraw limit</td>
                                    <td>Withdraw limit</td>
                                </tr>
                                <tr>
                                    <td>{stakedetail?.StakeWithdrawMin} - {stakedetail?.StakeWithdrawMax}</td>
                                    <td>{stakedetail?.StakeWithdrawrefFee} - {stakedetail?.StakeWithdrawrefMax}</td>
                                </tr>
                                <tr>
                                    <td>USDT Per Month</td>
                                    <td>USDT Per Month</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Understood
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}
