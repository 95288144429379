import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { user_details_two ,user_referal_history , stakeRewards } from '../../common/Api';
import DateFormatter from '../common/DateFormatter';


export default function StakeRewardsHistory() {
    const ph = JSON.parse(localStorage.getItem('ph'));
    const navigation = useNavigate();


    const [info, setInfo] = useState({})
    const [userreward, setUserReward] = useState([])
    const [userreferal, setUserReferal] = useState([])
    const [pageDetail, setPageDetail] = useState('wallet_page')



    const get_user_detail_two = async () => {
        const data = await user_details_two(pageDetail)
        setInfo(data)


    }

    const get_user_reward = async () => {
        try {
            const response = await stakeRewards()

            setUserReward(response.Data)
        } catch (error) {
            console.log(error)
        }
    }

    const get_referal_reward = async () => {
        try {
            const responses = await stakeRewards()

            setUserReferal(responses.Data)
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        get_user_detail_two();
        get_user_reward();
        get_referal_reward();


    }, [])

    // useEffect(() => {
    //     if (ph == 1) {
    //         navigation('/user/import/phrases')
    //     }

    // }, [ph])



    //console.log(info)

    //console.log(userreferal)




    return (
        <div class="container-xxl flex-grow-1 container-p-y">
            <div class="row">
                

                <div class="col-lg-12 mb-4">
                    <div class="card px-4 pt-3 h-100">
                    <div class="table-responsive">
                                    <table class="table table-borderless border-top">
                                        <tbody>
                                            {
                                                userreferal.length > 0 ? <>
                                                    {
                                                        userreferal.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <div class="d-flex justify-content-start align-items-center">
                                                                            {i + 1}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="d-flex flex-column">
                                                                            <p class="mb-0 fw-medium">{item.referral_id}</p>
                                                                            <small class="text-muted text-nowrap"><DateFormatter timestamp={item.created_on} /></small>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="d-flex flex-column">
                                                                            <p class="mb-0 fw-medium">{item.reward} USDT</p>

                                                                        </div>
                                                                    </td>


                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </> : <>

                                                </>
                                            }




                                        </tbody>
                                    </table>
                                </div>

                    </div>
                </div>








            </div>

        </div>
    )
}



