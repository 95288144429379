// BarChart.js
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import { CandlestickElement, CandlestickController } from 'chartjs-chart-financial'; // Correct imports

// Register necessary components
ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, CandlestickElement, CandlestickController, TimeScale);

const BarChart = ({ datas, amount, profit, foliyo, perce, todayprofita }) => {
    const [chartType, setChartType] = useState('br');
    const [days, setDays] = useState('');
    const [totalRewards, setTotalRewards] = useState(0);
    const [data, setData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Rewards',
                data: [],
                backgroundColor: [],
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    });

    const filterData = (timeFrame) => {
        const now = new Date();
        const cutoffDate = new Date();

        // Calculate the cutoff date based on the selected time frame
        switch (timeFrame) {
            case 365: cutoffDate.setFullYear(now.getFullYear() - 1);
                setDays('1 Year')
                break;
            case 90: cutoffDate.setMonth(now.getMonth() - 3);
                setDays('3 Month')
                break;
            case 30: cutoffDate.setMonth(now.getMonth() - 1);
                setDays('1 Month')
                break;
            case 7: cutoffDate.setDate(now.getDate() - 7);
                setDays('1 Week')
                break;
                setDays('1 Year')
            default: break;
        }

        // Filter the data based on the cutoff date
        const filteredData = datas.filter(item => new Date(item.created_on) >= cutoffDate);

        const labels = filteredData.map(item => {
            const date = new Date(item.created_on);
            return `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}`;
        });

        const rewards = filteredData.map(item => parseFloat(item.reward));
        const total = rewards.reduce((acc, curr) => acc + curr, 0);
        setTotalRewards(total.toFixed(2));


        const backgroundColors = rewards.map(reward => reward < 0 ? 'rgba(255, 99, 132, 0.2)' : 'rgba(75, 192, 192, 0.2)');

        setData({
            labels: labels,
            datasets: [
                {
                    label: 'Rewards',
                    data: rewards,
                    backgroundColor: backgroundColors,
                    borderColor: backgroundColors,
                    borderWidth: 1,
                },
            ],
        });

    };



    const handleChart = (event) => {
        const selectedType = event.target.value;
        setChartType(selectedType);
    };

    const handleTimeChange = (event) => {
        const time = parseInt(event.target.value);
        filterData(time);
    };

    useEffect(() => {
        filterData(365); // Initialize with 1 year of data
    }, [datas]);

    const prepareCandlestickData = (filteredData) => {
        return filteredData.map((item, index) => {
            const currentReward = parseFloat(item.reward);

            // Example logic to determine open, high, low, and close
            const open = index > 0 ? parseFloat(filteredData[index - 1].reward) : currentReward; // Previous reward as open
            const high = Math.max(open, currentReward) + 10;  // Example adjustment
            const low = Math.min(open, currentReward) - 10;   // Example adjustment
            const close = currentReward;

            return {
                x: new Date(item.created_on),
                o: open,
                h: high,
                l: low,
                c: close
            };
        });
    };
    const renderChart = () => {
        switch (chartType) {
            case 'br':
                const transformedData = data.datasets[0].data.map(value => value < 0 ? -value : value);
            const colors = data.datasets[0].data.map(value => value < 0 ? 'rgba(255, 99, 132, 0.6)' : 'rgba(75, 192, 192, 0.6)');

            return (
                <Bar 
                    data={{
                        labels: data.labels,
                        datasets: [{
                            label: 'Rewards',
                            data: transformedData,
                            backgroundColor: colors,
                            borderColor: colors,
                            borderWidth: 1,
                        }],
                    }} 
                    options={{
                        scales: {
                            y: {
                                beginAtZero: true, // Set to false to allow inverted bars
                                min: 0, // Set min based on the data
                                title: {
                                    display: true,
                                    text: 'Rewards',
                                },
                            },
                        },
                    }} 
                />
            );
            case 'ln':
                return <Line data={data} options={{ scales: { y: { beginAtZero: true , title: {
                    display: true,
                    text: 'Rewards',
                }, } } }} />;
            case 'ca':
                const candlestickData = prepareCandlestickData(datas);
                return (
                    <Bar data={{
                        datasets: [{
                            label: 'Candlestick',
                            data: candlestickData,
                            type: 'candlestick',
                            borderColor: 'rgba(75, 192, 192, 1)',
                        }]
                    }} options={{
                        scales: {
                            x: {
                                type: 'time',
                                time: {
                                    unit: 'day',
                                },
                                title: {
                                    display: true,
                                    text: 'Date'
                                },
                            },
                            y: {
                                title: {
                                    display: true,
                                    text: 'Value'
                                },
                                beginAtZero: true
                            }
                        }
                    }} />
                );
            default:
                return null;
        }
    };



    return (
        <div className='select2-primary'>
            <div className="row">
                <div className="col-lg-12 ">
                    <div className='terde-content-wrapper'>
                        <h4 className="text-bold text-white">My Trading</h4>
                        <div className='trading-list d-flex gap-2 align-items-center pb -1'>
                            <h6 className='mb-0 text-white'>Trading Portfolio </h6>
                            <h6 className='mb-0 text-white'>-</h6>
                            <h6 className='mb-0 text-white'>{foliyo && foliyo.toFixed(2)} USDT</h6>
                        </div>
                        <div className='trading-list d-flex gap-2 align-items-center pb-1'>
                            <h6 className='mb-0 text-white'>Trading Amount </h6>
                            <h6 className='mb-0 text-white'>-</h6>
                            <h6 className='mb-0 text-white'> {amount && amount} USDT</h6>
                        </div>
                        <div className='trading-list d-flex gap-2 align-items-center pb-1'>
                            <h6 className='mb-0 text-white'>Trading Profit </h6>
                            <h6 className='mb-0 text-white'>-</h6>
                            <h6 className='mb-0 text-white'>{profit && profit} USDT ({perce && perce.toFixed(2)}%)</h6>
                        </div>
                        <div className='trading-list d-flex gap-2 align-items-center pb-1'>
                            <h6 className='mb-0 text-white'>Today's PNL </h6>
                            <h6 className='mb-0 text-white'>-</h6>
                            <h6 className='mb-0 text-success fw-bold'>{todayprofita && todayprofita.toFixed(2)} USDT</h6>
                        </div>
                        <div className='trading-list d-flex gap-2 align-items-center pb-1'>
                            <h6 className='mb-0 text-white'>{days && days} PNL </h6>
                            <h6 className='mb-0 text-white'>-</h6>
                            <h6 className={`mb-0 text-success fw-bold`}>{totalRewards && totalRewards} USDT</h6>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <select id="formtabs-language" className="form-select" onChange={handleChart}>
                        <option value="br">Bar Chart</option>
                        <option value="ln">Line Chart</option>
                        {/* <option value="ca">Candlestick Chart</option> */}
                    </select>
                </div>
                <div className="col-lg-6">
                    <select id="time" onChange={handleTimeChange} className='form-select'>
                        <option value="0">Select Time</option>
                        <option value="365">1 Year</option>
                        <option value="90">3 Months</option>
                        <option value="30">1 Month</option>
                        <option value="7">1 Week</option>
                    </select>
                </div>
            </div>
            {renderChart()}
        </div>
    );
};

export default BarChart;
